/**
 * Mediaqueries generieren.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Mediaqueries generieren.
 *
 * @param string  $name Schluessel in der $breakpopints-Map.
 * @param boolean $push Schluessel hinzufuegen.
 */
@mixin respond-to($name, $push : false) {
	@if map-has-key($breakpoints, $name) {
		/*
		 * Schluessel existiert in der Map
		 */

		@media #{map-get($breakpoints, $name)} {
			@content;
		}
	} @else if $push != false {
		/*
		 * Existiert der Schluessel nicht in der Map, aber '$push' ist 'true',
		 * dann fuege den neunen Breakpoint der Map hinzu.
		 */

		$breakpoints : map-merge($breakpoints, ($name: $push)) !global;

		@include respond-to($name) {
			@content;
		}
	} @else {
		/*
		 * Existiert der Schluessel nicht in der Map und '$push' ist 'false',
		 * dann gib eine entsprechende Info aus.
		 */

		@warn "Unfortunately, no value could be retrieved from `#{$name}`. "
        + "Please make sure it is defined in `$breakpoints` map. "
        + "Or pass the media query as a second parameter to add it to the map.";
	}
}