/**
 * Box-Model-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Box-Model setzen.
 *
 * @param string  $val
 */
@mixin box-model($val : border-box) { box-sizing : $val; }