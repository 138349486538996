/**
 * Druckausgabe (Basis).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

* {
	-webkit-print-color-adjust : exact !important;

	// Schatten entfernen
	&,
	&::before,
	&::after {
		text-shadow : none !important;
		box-shadow  : none !important;
	}
}

body,
html {
	height   : auto;
	overflow : auto;
	padding  : 0;
}

body { text-rendering : optimizeLegibility; }

/* -----------------------------------------------------------------------------
 * @ Komponenten
 * -------------------------------------------------------------------------- */

table,
button,
input,
select,
textarea,
blockquote,
pre,
code,
figure,
li,
hr,
ul,
ol,
a { @extend %pmu-break-inside-avoid; }

// Seitenumbruch innerhalb von Medienelementen vermeiden.
audio,
img,
video,
.figure,
.thumbnail,
.vcard {
	@extend %pmu-break-before-auto;
	@extend %pmu-break-after-auto;
	@extend %pmu-break-inside-avoid;
}

nav,
.nav { @extend %pmu-no-print; }

/* -----------------------------------------------------------------------------
 * @ Typografie
 * -------------------------------------------------------------------------- */

// Seitenumbruch nach oder innerhalb einer Überschrift vermeiden.
h1,
h2,
h3,
h4,
h5,
h6 {
	@extend %pmu-break-after-avoid;
	@extend %pmu-break-inside-avoid;

	// Seitenumbruch vor einem Absatz vermeiden wenn eine Überschrift davor
	//steht.
	+ p { @extend %pmu-break-before-avoid; }
}

// Orphans and widows
h2,
h3,
h4,
h5,
h6,
p,
a {
	orphans : 3; // Min.-anzahl an Zeilen die am Seitenende verfügbar sein müssen
	widows  : 3; // Min.-anzahl an Zeilen die am Seitenende verfügbar sein müssen
}

abbr[title],
acronym[title] {
	border          : 0;
	text-decoration : none;

	&::after { content : " (" attr(title) ")"; }
}