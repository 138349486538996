/**
 * Alerts
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$alert-bg-color             : rgba($color-gray-dark, 0.25) !default;
$alert-color                : $color-gray-dark !default;
$alert-border-radius        : 0 !default; // $comp-border-radius-base !default; // (1)(2)
$alert-border-color         : $color-gray-dark !default;
$alert-border-style         : solid !default;
$alert-border-width         : 2px !default; //$comp-border-width !default;
//$alert-font-size            : $font-size-small !default; // (1)(2)
$alert-font-weight          : $font-weight-bold !default; // (1)(2)
$alert-link-font-weight     : $link-font-weight !default; // (1)(2)
$alert-link-text-decoration : underline !default; // (1)(2)
$alert-margin               : $gap-base-large 0 !default;
$alert-padding              : $comp-padding-vertical-large $comp-padding-horizontal-large !default;

$alert-success-bg-color     : rgba($brand-success, 0.25) !default;
$alert-success-border-color : $brand-success !default;
$alert-success-color        : $brand-success !default;
$alert-success-color-link   : $brand-success !default;

$alert-info-bg-color     : rgba($brand-info, 0.25) !default;
$alert-info-border-color : $brand-info !default;
$alert-info-color        : $brand-info !default;
$alert-info-color-link   : $brand-info !default;

$alert-warning-bg-color     : rgba($brand-warning, 0.25) !default;
$alert-warning-border-color : $brand-warning !default;
$alert-warning-color        : $brand-warning !default;
$alert-warning-color-link   : $brand-warning !default;

$alert-danger-bg-color     : rgba($brand-danger, 0.25) !default;
$alert-danger-border-color : $brand-danger !default;
$alert-danger-color        : $brand-danger !default;
$alert-danger-color-link   : $brand-danger !default;