/**
 * Overflow-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Overflow setzen.
 *
 * Examples:
 *     .selector {
 *         @include overflow(x, scroll);
 *     }
 *
 * @param string  $direction 'x' oder 'y'
 * @param boolean $type      'hidden', 'scroll' etc..
 */
@mixin overflow($direction: '', $type: hidden) {
	@if ($direction and $direction != '') {
		$property    : 'overflow-' + $direction;
		#{$property} : $type;
	} @else {
		overflow: $type;
	}
}