/**
 * Listen-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Events einem Formularelement zuweisen.
 * (Bootstrap adaptiert)
 *
 * @param string  $event        'hover', 'active', 'focus'
 * @param string  $color        Textfarbe
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe
 * @param boolean $shadow       Schattenwurf integrieren (Farbgebung auf Basis
 *                              von '$border-color')
 * @param boolean $shadow-inset Inline-Schattenwurf integrieren (Farbgebung auf
 *                              Basis von '$color')
 */
@mixin form-control-event($event : hover, $color : #555, $bg-color : #fff, $border-color : #e5e5e5, $shadow : true, $shadow-inset : false) {
	&:#{$event} {
		background-color : $bg-color;
		border-color     : $border-color;
		color            : $color;

		@if $shadow {
			$-rgba-border-color : rgba(red($border-color), green($border-color), blue($border-color), 0.2);

			@if $shadow-inset {
				$-rgba-color : rgba(red($color), green($color), blue($color), 0.1);
				$shadow : inset 0 0 6px $-rgba-color, 0 0 6px $-rgba-border-color;
				box-shadow : $shadow;
			} @else {
				$shadow : 0 0 6px $-rgba-border-color;
				box-shadow : $shadow;
			}
		} @else {
			box-shadow : none;
		}
	}
}

/**
 * Mit Statusklassen versehene Formularelemente bezueglich ihrer Fabrgebung etc.
 * definieren.
 * (Bootstrap adaptiert)
 *
 * Beispiele:
 *     .form-control.info
 *     .form-control.valid
 *     .form-control.warning
 *
 * @param string  $color        Textfarbe
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe
 * @param boolean $shadow       Schattenwurf integrieren (Farbgebung auf Basis
 *                              von '$border-color')
 * @param boolean $shadow-inset Inline-Schattenwurf integrieren (Farbgebung auf
 *                              Basis von '$color')
 */
@mixin form-control-validation-is-state($color : #555, $bg-color : #fff, $border-color : #e5e5e5, $shadow : true, $shadow-inset : false) {
	background-color : $bg-color;
	border-color     : $border-color;
	color            : $color;

	&:hover,
	&:focus {
		border-color : darken($border-color, 10%);
		color        : darken($color, 10%);
	}

	@if $shadow {
		&:focus {
			$-rgba-border-color : rgba(red($border-color), green($border-color), blue($border-color), 0.1);

			outline : none;

			@if $shadow-inset {
				$-rgba-color : rgba(red($color), green($color), blue($color), 0.07);
				$shadow      : inset 0 0 6px $-rgba-color, 0 0 8px lighten($border-color, 20%);

				box-shadow : $shadow;
			} @else {
				$shadow : 0 0 8px lighten($border-color, 20%);

				box-shadow : $shadow;
			}
		}
	} @else {
		box-shadow : none;
	}
}

/*
 * Formularelemente-Validierungsstatus bezueglich ihrer Farbgebung etc.
 * definieren.
 * (Bootstrap adaptiert)
 *
 * Beispiele:
 *     .has-error [Formularelemnte]
 *     .has-success [Formularelemnte]
 *     .has-warning [Formularelemnte]
 *
 * @param string  $color        Textfarbe
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe
 * @param boolean $shadow       Schattenwurf integrieren (Farbgebung auf Basis
 *                              von '$border-color')
 * @param boolean $shadow-inset Inline-Schattenwurf integrieren (Farbgebung auf
 *                              Basis von '$color')
 */
@mixin form-control-validation($color : #555, $bg-color : #fff, $border-color : #e5e5e5, $shadow : false, $shadow-inset : false) {
	.control-label,
	.radio,
	.checkbox,
	.radio-inline,
	.checkbox-inline,
	&.radio label,
	&.checkbox label,
	&.radio-inline label,
	&.checkbox-inline label { color : $color; }

	.form-control {
		border-color : $border-color;

		@if $shadow == "true" AND $shadow-inset == "true" {
			//$-rgba-color : rgba(red($color), green($color), blue($color), 0.07);
			//$_shadow      : inset 0 0 6px $-rgba-color, 0 0 8px lighten($border-color, 20%);
			//box-shadow : $_shadow;

			&:focus {
				$_shadow : inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($border-color, 20%);

				border-color : darken($border-color, 10%);
				box-shadow   : $_shadow;
			}
		} @else {
			box-shadow : none;

			&:focus { box-shadow : none; }
		}
	}

	.input-group-addon {
		color            : $color;
		border-color     : $border-color;
		background-color : $bg-color;
	}

	.form-control-feedback,
	.help-block {
		color : $color;

		&.state { display : block; }
	}
}