/**
 * Events.
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/// Event wrapper
///
/// @author Harry Roberts
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
/// @param {bool} $self [false] - Whether or not to include current selector
@mixin on-event($self: false) {
	@if $self {
		&,
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	} @else {
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	}
}