/**
 * Visitenkarten
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$vcard-bg-color      : $color-white !default; // (1)
$vcard-border-color  : $color-gray !default;
$vcard-border-radius : 0 !default; // $comp-border-radius-base !default; // (1)
$vcard-border-style  : solid;
$vcard-border-width  : 3px;
$vcard-color         : $body-text-color !default; // (1)
$vcard-max-width     : 290px;
$vcard-padding       : $gap-base-small;

$vcard-image-bg-color : $color-gray !default;
//$vcard-image-padding  : 0 // (1)


$vcard-name-color       : $body-text-color !default; // (1)(2)
$vcard-name-font-size   : $font-size-large; // (1)(2)
$vcard-name-font-weight : $font-weight-bold; // (1)(2)
//$vcard-name-font-style  : $font-style-italic; // (1)(2)

$vcard-function-color       : $body-text-color !default; // (1)(2)
$vcard-function-font-size   : ($font-size-large - 1); // (1)(2)
$vcard-function-font-weight : $font-weight-light; // (1)(2)
//$vcard-function-font-style  : $font-style-italic; // (1)(2)

//$vcard-link-color       : $vcard-color !default; // (1)(2)
//$vcard-link-color-hover :  !default; // (1)(2)