/**
 * Schrift
 *
 * Grundkonfiguration
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =============================================================================
 *
 * Body
 *
 * ========================================================================== */

$body-text-color   : $color-black;
$body-font-family  : $font-family-base;
$body-line-height  : $line-height-base;
$body-font-size    : $font-size-base;
$body-font-style   : $font-style-normal;
$body-font-variant : $font-variant;
$body-font-weight  : $font-weight-light;
$body-text-align   : left;

/* =============================================================================
 *
 * Ueberschriften
 *
 * ========================================================================== */

$h1-color          : $brand-secondary;
$h1-font-size      : 40px;
$h1-font-style     : normal;
$h1-font-variant   : normal;
$h1-font-weight    : $font-weight-light;
$h1-line-height    : 20px;
$h1-margin         : $margin-base-vertical-large 0; // top right bottom left
$h1-small-color    : $h1-color;
//$h1-text-transform : uppercase; // (1)(2)

$h2-color          : $brand-secondary;
$h2-font-size      : 25px;
$h2-font-style     : normal;
$h2-font-variant   : normal;
$h2-font-weight    : $font-weight-bold;
$h2-line-height    : 20px;
$h2-margin         : $margin-base-vertical 0; // top right bottom left
$h2-small-color    : $h2-color;
//$h2-text-transform : uppercase; // (1)(2)

$h3-color          : $brand-secondary;
$h3-font-size      : 18px;
$h3-font-style     : normal;
$h3-font-variant   : normal;
$h3-font-weight    : $font-weight-bold;
$h3-line-height    : 20px;
$h3-margin         : $margin-base-vertical 0; // top right bottom left
$h3-small-color    : $h3-color;
//$h3-text-transform : none; // (1)(2)

$h4-color          : $brand-secondary;
$h4-font-size      : 16px;
$h4-font-style     : normal;
$h4-font-variant   : normal;
$h4-font-weight    : $font-weight-normal;
$h4-line-height    : 20px;
$h4-margin         : $margin-base-vertical 0; // top right bottom left
$h4-small-color    : $h4-color;
//$h4-text-transform : none; // (1)(2)

/* =============================================================================
 *
 *  Sonstiges
 *
 * ========================================================================== */

$text-muted-color : $color-gray-light !default;

$mark-bg-color : $state-warning-bg-color !default;
$mark-padding  : .2em !default;

$blockquote-bg-color     : rgba($brand-primary, 0.2) !default;
$blockquote-border-color : lighten(desaturate(adjust-hue($brand-primary, -0.8000), 15.2401), 33.3333) !default;
$blockquote-border-style : solid;
$blockquote-border-width : 3px;
$blockquote-color        : $brand-primary !default;
//$blockquote-font-family  : $font-family-base !default; // (1)(2)
$blockquote-font-size    : 30px; // (1)(2)
$blockquote-font-style   : $font-style-italic;
$blockquote-font-weight  : $font-weight-light;
//$blockquote-margin       : $margin-base-vertical-large auto; // top right bottom left (1)(2)
$blockquote-padding      : $gap-base-large $gap-base-large $gap-base-large (ceil($gap-base-large * 2.5));

$abbr-border-bottom : 1px dashed #e2e200 !default;

$selection-bg-color : #cce3e8 !default;
$selection-color    : $body-text-color;

/* -----------------------------------------------------------------------------
 * Code
 * -------------------------------------------------------------------------- */

$code-bg-color      : $color-gray-light;
$code-border-radius : $border-radius-base;
$code-color         : $color-gray-dark;

$kbd-bg-color   : $color-gray-darker;
$kbd-color      : #fff;
$kbd-box-shadow : none;

$pre-color         : $color-gray-light;
$pre-bg-color      : #fff;
$pre-border        : 1px solid $color-gray-lighter;
$pre-border-radius : $border-radius-base;