/**
 * Basis
 *
 * Hauptkonfiguration mit zusaetzlichem Konfigurationsimport am Ende.
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * Sass Colour Function Calculator
 * http://razorltd.github.io/sasscolourfunctioncalculator/
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$debug-mode : true;

/* =============================================================================
 *
 * Schriftangaben
 *
 * ========================================================================== */

// Schrifteinheit die als Standard genutzt werden soll (px, em ,rem).
$font-unit-base : 'rem';

// Standardschriftpfad
$base-font-external-path : '../fonts/';

// Standardschriftgroessen
$font-size-base   : 16px;
$font-size-small  : 14px;
$font-size-xsmall : 13px;
$font-size-large  : 18px;
$font-size-xlarge : 22px;

// Standardschriftauszeichungen
$font-variant         : normal !default;
$font-smoothing       : antialiased !default;
$font-style-normal    : normal !default;
$font-style-italic    : italic !default;
$font-weight-light    : 300 !default;
$font-weight-normal   : 400 !default;
$font-weight-medium   : 500 !default; // $font-weight-normal !default;
$font-weight-semibold : 600 !default; // $font-weight-normal !default;
$font-weight-bold     : 700 !default;

// Standardzeilenhoehe
$line-height-base     : 1.5 !default; // 24/16
$line-height-computed : floor(($font-size-base * $line-height-base)) !default; // ~22px
$line-height-large    : 1.3333333 !default;
$line-height-small    : 1.25 !default; // 20/16
$line-height-xsmall   : 1.125 !default; // 18/16

// Standardschriftfamilien
//$font-family-primary   : "Open Sans", Helvetica, Arial, sans-serif !default;
$font-family-primary   : "Roboto", Helvetica, Arial, sans-serif !default;
//$font-family-secondary : "Open Sans", Helvetica, Arial, sans-serif !default;
$font-family-secondary : "Roboto", Helvetica, Arial, sans-serif !default;
$font-family-base      : $font-family-primary !default;
$font-family-monospace : Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-serif     : Georgia, "Times New Roman", Times, serif !default;
$font-family-headings  : $font-family-secondary !default;

/* =============================================================================
 *
 * Abstaende, Radius etc.
 *
 * ========================================================================== */

$gap-base        : 15px;
$gap-base-large  : 30px;
$gap-base-small  : 10px;
$gap-base-xsmall : 5px;

$margin-base-horizontal        : 15px;
$margin-base-horizontal-large  : 20px;
$margin-base-horizontal-small  : 10px;
$margin-base-horizontal-xsmall : 5px;

$margin-base-vertical        : 15px;
$margin-base-vertical-large  : 20px;
$margin-base-vertical-small  : 10px;
$margin-base-vertical-xsmall : 5px;

$padding-base-horizontal        : 15px;
$padding-base-horizontal-large  : 20px;
$padding-base-horizontal-small  : 10px;
$padding-base-horizontal-xsmall : 5px;

$padding-base-vertical        : 15px;
$padding-base-vertical-large  : 20px;
$padding-base-vertical-small  : 10px;
$padding-base-vertical-xsmall : 5px;

$border-radius-base   : 3px !default; //2px
$border-radius-large  : 6px !default; //4px
$border-radius-small  : 1px !default; //1px
$border-radius-xsmall : 1px !default; //1px

/* =============================================================================
 *
 * Breakpoints (Mediaqueries)
 *
 * -----------------------------------------------------------------------------
 * Desktop-First-Methode
 * ========================================================================== */

$breakpoints : (
	'c420' : 'only screen and (max-width : 420px)',
	'c640' : 'only screen and (max-width : 640px)',
	'xs'   : 'only screen and (max-width : 767px)',
	'sm'   : 'only screen and (max-width : 991px)',
	'md'   : 'only screen and (max-width : 1199px)',
	'lg'   : 'only screen and (min-width : 1200px)'

//	'xs'  : 'only screen and (max-width : 767px)',
//	'sm'  : 'only screen and (max-width : 768px)',
//	'md'  : 'only screen and (max-width : 992px)',
//	'lg'  : 'only screen and (min-width : 993px)'
//	'lg'  : 'only screen and (max-width : 1200px)'
);

/* =============================================================================
 *
 * Cursor
 *
 * ========================================================================== */

$cursor-cell        : cell !default;
$cursor-col-resize  : col-resize !default;
$cursor-copy        : copy !default;
$cursor-crosshair   : crosshair !default;
$cursor-default     : default !default;
$cursor-disabled    : $cursor-default !default;
$cursor-grab        : grab !default;
$cursor-grabbing    : grabbing !default;
$cursor-help        : help !default;
$cursor-move        : move !default;
$cursor-no-drop     : no-drop !default;
$cursor-none        : none !default;
$cursor-not-allowed : not-allowed !default;
$cursor-pointer     : pointer !default;
$cursor-progress    : progress !default;
$cursor-text        : text !default;
$cursor-wait        : wait !default;
$cursor-zoom-in     : zoom-in !default;
$cursor-zoom-out    : zoom-out !default;

/* =============================================================================
 *
 * Farben
 *
 * ========================================================================== */

$color-white : #fff !default;
$color-black : #000 !default;

$color-gray-darker  : #605f5f !default;
$color-gray-dark    : #807f7f !default;
$color-gray         : #dedcdc !default;
$color-gray-light   : #f4f4f4 !default;
$color-gray-lighter : #f8f8f8 !default;

$color-even : $color-gray;
$color-odd  : $color-gray-light;

//$brand-primary     : #0e3e77 !default;
$brand-primary     : #92C5EB !default;
//$brand-secondary   : #cf0b48 !default;
$brand-secondary   : #2A89CF !default;
$brand-tertiary    : #2A89CF !default;

$brand-danger  : #ea4b77 !default;
$brand-info    : #46a6b1 !default;
$brand-invalid : $brand-danger !default;
$brand-success : $brand-tertiary !default;
$brand-valid   : $brand-success !default;
$brand-warning : #f3bb5b !default;

/* -----------------------------------------------------------------------------
 * Status
 * -------------------------------------------------------------------------- */

$state-danger-color        : $brand-danger !default;
$state-danger-bg-color     : lighten($state-danger-color, 30%) !default;
$state-danger-border-color : $state-danger-color !default;

$state-info-color        : $brand-info !default;
$state-info-bg-color     : lighten($state-info-color, 30%) !default;
$state-info-border-color : $state-info-color !default;

$state-invalid-color        : $brand-invalid !default;
$state-invalid-bg-color     : lighten($state-invalid-color, 30%) !default;
$state-invalid-border-color : $state-invalid-color !default;

$state-success-color        : $brand-success !default;
$state-success-bg-color     : lighten($state-success-color, 30%) !default;
$state-success-border-color : $state-success-color !default;

$state-valid-color        : $brand-valid !default;
$state-valid-bg-color     : lighten($state-valid-color, 30%) !default;
$state-valid-border-color : $state-valid-color !default;

$state-warning-color        : $brand-warning !default;
$state-warning-bg-color     : lighten($state-warning-color, 30%) !default;
$state-warning-border-color : $state-warning-color !default;

/* =============================================================================
 *
 * Theme-Map
 *
 * ========================================================================== */

$themes : (
	'sjs' : (
		'brand-primary' : #db4e00,
		'logo' : (
			'src'    : '../images/logo.png',
			'height' : 346px,
			'width'  : 360px
		)
	),
);

/* =============================================================================
 *
 * Konfigurationsimport
 *
 * -----------------------------------------------------------------------------
 * Zwecks Uebersichtlichkeit ausgegliedert/separiert.
 * ========================================================================== */

@import '_typo';
@import '_scaffolding';
@import '_navigation';
@import '_components';