/**
 * Formulare
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$form-margin : 0; // ($margin-base-vertical-large * 2);

/* -----------------------------------------------------------------------------
 * Fieldset
 * -------------------------------------------------------------------------- */

$fieldset-bg-color : transparent !default;
$fieldset-margin   : $margin-base-vertical-large 0;
$fieldset-padding  : 0;

$legend-bg-color      : transparent !default;
$legend-border-bottom : 0 none;
$legend-color         : $h3-color !default;
$legend-font-size     : $h3-font-size;
$legend-font-weight   : $h3-font-weight; // (1)(2)
$legend-margin        : $margin-base-vertical-large 0;
$legend-padding       : 0;

/* -----------------------------------------------------------------------------
 * Label
 * -------------------------------------------------------------------------- */

$label-color       : $body-text-color !default;
$label-font-style  : $font-style-normal;
$label-font-weight : $font-weight-light;

/* -----------------------------------------------------------------------------
 * Eingabe-, Auswahlelemente
 * -------------------------------------------------------------------------- */

$input-bg-color     : $color-gray !default;
$input-border-color : $color-gray !default;
$input-box-shadow   : none !default;
$input-border-width : $comp-border-width;
$input-border-style : $comp-border-style;
$input-color        : $color-black !default;
$input-font-size    : $font-size-base;
$input-font-style   : $font-style-normal;
$input-font-weight  : $font-weight-light;
//$input-height       : ($line-height-computed + ($comp-padding-vertical-base * 2) + 2);
$input-height       : ($line-height-computed + ($comp-padding-vertical-large * 2) + 2);
$input-height-large : (ceil($font-size-large * $line-height-large) + ($comp-padding-vertical-large * 2) + 2);
$input-height-small : (floor($font-size-small * $line-height-small) + ($comp-padding-vertical-small * 2) + 2);

/*
 * Border radius
 */

$input-border-radius       : $comp-border-radius-base;
$input-border-radius-large : $comp-border-radius-large;
$input-border-radius-small : $comp-border-radius-small;

/*
 * Events, Status
 */

$input-bg-color-hover     : $input-bg-color !default;
$input-border-color-hover : $brand-secondary !default;
$input-color-hover        : $input-color !default;

$input-bg-color-focus     : $input-bg-color-hover !default;
$input-border-color-focus : $input-border-color-hover !default;
$input-color-focus        : $input-color-hover !default;

$input-bg-color-placeholder     : $input-bg-color !default;
$input-border-color-placeholder : $input-border-color !default;
$input-color-placeholder        : $color-gray-dark !default;

$input-bg-color-readonly     : lighten(desaturate(adjust-hue($color-gray-dark, 0.0000), 0.3922), 31.1765) !default;
$input-border-color-readonly : lighten(saturate(adjust-hue($color-gray-dark, 0.0000), 1.8979), 24.3137) !default;
$input-color-readonly        : $color-gray-dark !default;
$input-opacity-readonly      : 1 !default;

$input-bg-color-disabled     : $input-bg-color-readonly !default;
$input-border-color-disabled : $input-border-color-readonly !default;
$input-color-disabled        : $color-gray-dark !default;
$input-opacity-disabled      : .3 !default;

/*
 * Input Group
 */

$input-group-addon-bg-color     : $color-gray-light !default; //darken($input-bg-color, 10%) !default;
$input-group-addon-border-color : $color-gray-light !default; //darken($input-border-color, 10%) !default;
$input-group-addon-border-width : $input-border-width;
$input-group-addon-border-style : $input-border-style;
$input-group-addon-color        : $input-color !default; //darken($input-color, 10%) !default;
$input-group-addon-font-size    : $input-font-size;
$input-group-addon-font-weight  : $font-weight-normal;

/* -----------------------------------------------------------------------------
 * Datepicker
 * -------------------------------------------------------------------------- */

$input-datepicker-icon-size : 30px;
$input-datepicker-icon-pos  : right;

/* -----------------------------------------------------------------------------
 * Platzhalter
 * -------------------------------------------------------------------------- */

$form-placeholder-color      : $input-color-placeholder !default;
$form-placeholder-font-style : $font-style-italic;

/* -----------------------------------------------------------------------------
 * Trenner
 * -------------------------------------------------------------------------- */

$form-delim-bg-color      : $color-white !default;
$form-delim-border-color  : $color-gray-light !default;
$form-delim-border-style  : solid;
$form-delim-border-width  : 2px;
$form-delim-color         : $brand-primary !default;
//$form-delim-font-size    : .875em; // (1)(2)
$form-delim-font-weight   : $font-weight-normal; // (1)(2)
$form-delim-margin        : $gap-base-large 0;
$form-delim-text-align    : center;
$form-delim-text-padding  : 0 $gap-base-xsmall;