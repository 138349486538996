/**
 * Ausrichtungen/Zentrierung von Elementen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Element zentrieren.
 *
 * @param string $width
 */
@mixin center-block($width: '') {
	@extend %center-block;

	@if ($width != '') {
		@if (unit($width) == '') {
			@warn "$width = #{$width} - Erwarten wird eine numerischer Wert mit einer Maßeinheit.";
		} @else {
			width : $width;
		}
	}
}