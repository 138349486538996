/**
 * Pagination-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Pagination
 * (Bootstrap adaptiert)
 *
 * @param string  $padding-vertical   Vertikaler Innenabstand
 * @param string  $padding-horizontal Horizontaler Innenabstand
 * @param string  $font-size          Schriftgroesse
 * @param string  $border-radius      Border-Radius
 */
@mixin pagination-size($padding-vertical, $padding-horizontal, $font-size : null, $border-radius : null) {
	> li {
		> a,
		> span {
			padding   : $padding-vertical $padding-horizontal;

			@if ($font-size) {
				font-size : $font-size;
			}
		}

		@if ($border-radius) {
			&:first-child {
				> a,
				> span {
					border-bottom-left-radius : $border-radius;
					border-top-left-radius    : $border-radius;
				}
			}

			&:last-child {
				> a,
				> span {
					border-bottom-right-radius : $border-radius;
					border-top-right-radius    : $border-radius;
				}
			}
		}
	}
}