/**
 * Alerts
 *
 * Bootstrap adaptierte und abgewandelte Variante.
 *
 * @copyright 2017 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Alert variant.
 * (Bootstrap adaptiert)
 *
 * @param string  $bg-color     Hintergrundfarbe
 * @param string  $border-color Rahmenfarbe (wenn null dann autom. Bestimmung
 *                              per darken() anhand $bg-color)
 * @param string  $color        Textfarbe (wenn null dann autom. Bestimmung per
 *                              darken() anhand $bg-color)
 * @param string  $link-color   Textfarbe (wenn null dann autom. Bestimmung per
 *                              darken() anhand $bg-color)
 */
@mixin alert-variant($bg-color, $border-color : null, $color : null, $color-link : null) {
	@if ($border-color == null) {
		$border-color : darken($bg-color, 10%);
	}

	@if ($color == null) {
		$color : lighten($bg-color, 40%);
	}

	@if ($color-link == null) {
		$color-link : $color;
	}

	background-color : $bg-color;
	border-color     : $border-color;
	color            : $color;

	hr { border-top-color : $border-color; }

	.alert-link,
	a:not(.btn) { color : $color-link; }

	> .close {
		color   : $color;
		opacity : .4;

		&:hover,
		&:active,
		&:focus { opacity : 1; }
	}
}