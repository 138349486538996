/**
 * Alerts.
 *
 * @author Bootstrap
 * @author Heiko Pfefferkorn (https://i-fabrik.de, Modifications)
 */

/**
 * Buttontyp.
 * (Bootstrap adaptiert)
 *
 * @param string  $color              Textfarbe
 * @param string  $bg-color            Hintergrundfarbe
 * @param string  $border-color       Rahmenfarbe
 * @param string  $color-hover        Textfarbe Mouseover etc. (Standard '$color').
 * @param string  $bg-color-hover      Hintergrundfarbe Mouseover. Wenn nicht
 *                                    angegeben, dann autom. Bestimmung per
 *                                    'darken()'.
 * @param string  $border-color-hover Rahmenfarbe Mouseover. Wenn nicht
 *                                    angegeben, dann autom. Bestimmung per
 *                                    'darken()'.
 */
@mixin button-variant($color, $bg-color, $border-color, $color-hover : $color, $bg-color-hover : null, $border-color-hover : null) {
	color            : $color;
	background-color : $bg-color;
	border-color     : $border-color;

	&.hover,
	&:hover,
	&.active,
	&:active,
	&.active.hover,
	&:active:hover,
	&.active.focus,
	&:active:focus,
	&.focus,
	&:focus,
	.open > &.dropdown-toggle {
		color : $color-hover;

		@if ($bg-color-hover) {
			background-color : $bg-color-hover;
		} @else {
			background-color : darken($bg-color, 10%);
		}

		@if ($border-color-hover) {
			border-color : $border-color-hover;
		} @else {
			border-color : darken($border-color, 12%);
		}
	}

	&:active,
	&.active,
	.open > &.dropdown-toggle { background-image : none; }

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&.hover,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			color            : $color;
			background-color : $bg-color;
			border-color     : $border-color;
		}
	}

	.badge {
		color            : $border-color;
		background-color : $color;
	}
}

/**
 * Buttongroesse.
 * (Bootstrap adaptiert)
 *
 * @param string  $padding-vertical
 * @param string  $padding-horizontal
 * @param string  $line-height
 * @param string  $border-radius
 */
@mixin button-size($padding-vertical : 6px, $padding-horizontal : 12px, $border-radius : 0) {
	border-radius : $border-radius;
	padding       : $padding-vertical $padding-horizontal;
}