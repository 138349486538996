/**
 * Links
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$link-color           : $brand-secondary !default;
$link-font-weight     : $font-weight-bold;
$link-text-decoration : none;

$link-color-hover           : lighten($brand-secondary, 10%) !default;
$link-text-decoration-hover : underline;

$link-color-active           : $link-color-hover !default;
$link-text-decoration-active : $link-text-decoration;

$link-color-focus           : $link-color-hover !default;
$link-text-decoration-focus : $link-text-decoration;

$link-color-crossed-out           : $color-gray !default;
$link-text-decoration-crossed-out : line-through;