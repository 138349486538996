/**
 * Listen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$list-margin      : $margin-base-vertical-large 0;
$list-padding     : 0;
$list-item-margin : $margin-base-vertical-xsmall 0;

/* -----------------------------------------------------------------------------
 * Liste mit Links
 * -------------------------------------------------------------------------- */

$list-links-border-width  : 1px;
$list-links-border-style  : solid;
$list-links-border-color  : $color-gray-light !default;
$list-links-child-padding : $padding-base-vertical 0;
$list-links-color         : $color-gray-darker !default; // (1) (2)
$list-links-color-hover   : $brand-primary !default; // (1) (2)

/* -----------------------------------------------------------------------------
 * Liste mit Rahmen
 * -------------------------------------------------------------------------- */

$list-bordered-border-width  : 1px;
$list-bordered-border-style  : solid;
$list-bordered-border-color  : $color-gray-lighter !default;
$list-bordered-child-padding : $comp-padding-vertical-large $comp-padding-horizontal-large;

/* -----------------------------------------------------------------------------
 * Tabellenliste
 * -------------------------------------------------------------------------- */

$list-table-border-width  : 1px;
$list-table-border-style  : solid;
$list-table-border-color  : $color-gray !default;