/**
 * Druckausgabe (Helfer).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

%pmu-reset-max-width {
	max-width     : none;
	padding-left  : 0;
	padding-right : 0;
	width         : 100%;
}

%pmu-no-print { display: none !important; }

%pmu-print { display: block !important; }

%pmu-break-after-always { page-break-after : always; }

%pmu-break-after-avoid { page-break-after : avoid; }

%pmu-break-after-auto { page-break-after : auto; }

%pmu-break-before-always { page-break-before : always; }

%pmu-break-before-avoid { page-break-before : avoid; }

%pmu-break-before-auto { page-break-before : auto; }

%pmu-break-inside-avoid { page-break-inside : avoid; }

%pmu-break-inside-auto { page-break-inside : auto; }

%pmu-break-inside-inherit { page-break-inside : inherit; }