/**
 * Collapse
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

//$collapse-border-color : $brand-tertiary !default;
//$collapse-border-style : solid !default;
//$collapse-border-width : 2px !default;

/**
 * Toggle
 */

$collapse-toggle-bg-color     : $color-gray-lighter !default;
$collapse-toggle-border-color : $color-gray !default;
$collapse-toggle-border-style : solid;
$collapse-toggle-border-width : $comp-border-width;
$collapse-toggle-color        : $brand-secondary !default;
$collapse-toggle-font-size    : $font-size-large; // (1)(2)
$collapse-toggle-font-weight  : $font-weight-bold; // (1)
$collapse-toggle-margin       : $comp-margin-horizontal-base 0 0;
$collapse-toggle-padding      : $comp-padding-vertical-base  $comp-padding-horizontal-base;

$collapse-toggle-icon-size          : 30px;
$collapse-toggle-icon-border-radius : 0;
//$collapse-toggle-icon-bg-color      : transparent !default; // (1)
$collapse-toggle-icon-content       : "\f055";
$collapse-toggle-icon-color         : $collapse-toggle-color !default;
$collapse-toggle-icon-font-size     : .9375em;
$collapse-toggle-icon-align         : left; // left oder right
$collapse-toggle-icon-space-around  : $comp-padding-horizontal-small;

// Hover
$collapse-toggle-bg-color-hover     : $collapse-toggle-bg-color !default;
$collapse-toggle-border-color-hover : $collapse-toggle-border-color !default;
$collapse-toggle-color-hover        : $brand-primary !default;

$collapse-toggle-icon-bg-color-hover : transparent !default;
$collapse-toggle-icon-content-hover  : "\f055" !default;
$collapse-toggle-icon-color-hover    : $brand-primary !default;

// Active
$collapse-toggle-bg-color-active     : $collapse-toggle-bg-color !default;
$collapse-toggle-border-color-active : $collapse-toggle-border-color !default;
$collapse-toggle-color-active        : $brand-primary !default;

$collapse-toggle-icon-bg-color-active : transparent !default;
$collapse-toggle-icon-content-active  : "\f056" !default;
$collapse-toggle-icon-color-active    : $brand-primary !default;

/**
 * Panel
 */

$collapse-panel-border-color : $color-gray-lighter !default;
$collapse-panel-border-style : solid !default;
$collapse-panel-border-width : 3px !default;
$collapse-panel-padding      : $comp-padding-horizontal-base $comp-padding-horizontal-base ($comp-padding-horizontal-base - $comp-padding-horizontal-base) !default;