/**
 * Hamburger.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Hamburger.
 *
 * @param string  $options  Map mit optionalen Einstellungen
 */
@mixin hamburger($options : ()) {
	$settings : map-merge((
		'active-class' : 'is-active',
		'bg-color'     : transparent,
		'border'       : 0 none,
		'color'        : #878787,
		'duration'     : 0.25s,
		'opacity'      : 0.5,

		'layer-border-radius' : 0,
		'layer-height'        : 4px,
		'layer-spacing'       : 6px,
		'layer-width'         : 40px,

		'transition-duration'        : 0.35s,
		'transition-timing-function' : ease,

		'hover' : (
			'bg-color'                   : transparent,
			'border'                     : 0 none,
			'color'                      : #000,
			'opacity'                    : 1
		),
		'active' : (
			'bg-color' : transparent,
			'border'   : 0 none,
			'color'    : #000,
			'opacity'  : 1,
		)
	), $options);

	$class-active : map-get($settings, 'active-class');
	$hover-data   : map-get($settings, 'hover');
	$active-data  : map-get($settings, 'active');

	// Normalize
	background-color           : map-get($settings, 'bg-color');
	border                     : map-get($settings, 'border');
	cursor                     : pointer;
	display                    : inline-block;
	font                       : inherit;
	margin                     : 0;
	overflow                   : visible;
	opacity                    : map-get($settings, 'opacity');
	padding                    : 0;
	text-transform             : none;
	transition-property        : all;
	transition-duration        : map-get($settings, 'transition-duration');
	transition-timing-function : map-get($settings, 'transition-timing-function');

	.hamburger {
		&-box {
			display  : block;
			height   : (map-get($settings, 'layer-height') * 3) + (map-get($settings, 'layer-spacing') * 2);
			position : relative;
			width    : map-get($settings, 'layer-width');
		}

		&-inner {
			top        : 50%;
			margin-top : map-get($settings, 'layer-height') / -2;

			&,
			&::before,
			&::after {
				background-color           : map-get($settings, 'color');
				border-radius              : map-get($settings, 'layer-border-radius');
				display                    : block;
				height                     : map-get($settings, 'layer-height');
				position                   : absolute;
				transition-duration        : map-get($settings, 'transition-duration');
				transition-property        : all;
				transition-timing-function : map-get($settings, 'transition-timing-function');
				width                      : map-get($settings, 'layer-width');
			}

			&::before,
			&::after {
				content : "";
				display : block;
			}

			&::before { top : (map-get($settings, 'layer-spacing') + map-get($settings, 'layer-height')) * -1; }

			&::after { bottom : (map-get($settings, 'layer-spacing') + map-get($settings, 'layer-height')) * -1; }
		}
	}

	&:hover {
		background-color : map-get($hover-data, 'bg-color');
		border           : map-get($hover-data, 'border');
		opacity          : map-get($hover-data, 'opacity');

		.hamburger-inner {
			&,
			&::before,
			&::after { background-color : map-get($hover-data, 'color'); }
		}
	}

	&.#{$class-active} {
		background-color : map-get($active-data, 'bg-color');
		border           : map-get($active-data, 'border');
		opacity          : map-get($active-data, 'opacity');

		.hamburger-inner {
			&,
			&::before,
			&::after { background-color : map-get($active-data, 'color'); }
		}
	}

	// Typ: Slider
	&.hamburger--slider {
		.hamburger-inner {
			top : (map-get($settings, 'layer-spacing') / 2);

			&::before { top : (map-get($settings, 'layer-height') + map-get($settings, 'layer-spacing')); }

			&::after { top : (map-get($settings, 'layer-height') * 2) + (map-get($settings, 'layer-spacing') * 2); }
		}

		&.#{$class-active} {
			.hamburger-inner {
				$y-offset : (map-get($settings, 'layer-height') + map-get($settings, 'layer-spacing'));

				transform : translate3d(0, $y-offset, 0) rotate(45deg);

				&::before {
					transform : rotate(-45deg) translate3d(map-get($settings, 'layer-width') / -7, map-get($settings, 'layer-spacing') * -1, 0);
					opacity   : 0;
				}

				&::after { transform : translate3d(0, $y-offset * -2, 0) rotate(-90deg); }
			}
		}
	}
}