/**
 * Stapel
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

//$stack-delimiter-border : 1px solid $color-gray !default;
$stack-item-gap         : ($gap-base-large * 2) 0;

$stack-article-image-offset         : 4%;
$stack-article-image-width          : 28%;
//$stack-article-item-margin          : ($gap-base-small * 2); // (1)
$stack-article-title-color          : $h3-color !default; // (1)(2)
$stack-article-title-font-size      : $h3-font-size; // (1)(2)
$stack-article-title-font-weight    : $h3-font-weight; // (1)(2)
$stack-article-title-margin         : $h3-margin; // (1)(2)
//$stack-article-title-text-transform : none; // (1)(2)

//$stack-article-readmore-color           : $brand-primary !default; // (1)(2)
$stack-article-readmore-font-size       : $font-size-large; // (1)(2)
//$stack-article-readmore-font-weight     : $font-weight-normal; // (1)(2)
$stack-article-readmore-text-decoration : none; // (1)(2)