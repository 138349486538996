/**
 * Farbverlauf-Vorlagen.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

@mixin calc-grid-column-width($index: 0, $grid-columns: 0) {
	@if ($index > 0) and ($grid-columns > 0) {
		width : percentage(($index / $grid-columns));
	}
}

/**
 * Neue Grid-Spaltendefinitionen hinzufügen.
 *
 * @param array $gutter-width Spaltenabstand
 * @param string $gutter-width Spaltenabstand
 * @param string $grid-columns Anzahl der Spalten
 */
@mixin grid-append-new-columns($selector: null, $gutter-width: 0, $grid-columns: 12) {
	@if (type-of($selector) == 'string' or type-of($selector) == 'list') {
		@if (type-of($selector) == 'string') {
			$selector : ($selector);
		}

		$list: ();

		@for $i from 1 through $grid-columns {
			@each $item in $selector {
				$s : '.col-' + $item + '-' + $i;

				$list: append($list, unquote($s), 'comma');
			}
		}

		#{$list} {
			float         : left;
			margin-left   : 0;
			margin-right  : 0;
			min-height    : 1px;
			padding-left  : $gutter-width;
			padding-right : $gutter-width;
			position      : relative;
		}

		@for $i from 1 through $grid-columns {
			$sw: ();

			@each $item in $selector {
				$s : '.col-' + $item + '-' + $i;

				$sw: append($sw, unquote($s), 'comma');
			}

			#{$sw} {
				@include calc-grid-column-width($i, $grid-columns);
			}
		}
	} @else {
		@warn "'grid-append-new-columns()' needs a selector definition";
	}
}

/**
 * Grid-Spalten generieren.
 *
 * @param string $gutter-width Spaltenabstand
 * @param string $grid-columns Anzahl der Spalten
 */
@mixin grid-gutter-variant-make-columns($gutter-width, $grid-columns) {
	$i    : 1;
	$list : ".col-c420-#{$i}, .col-c640-#{$i}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";

	@for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
	}

	> #{$list} {
		padding-left  : ceil(($gutter-width / 2));
		padding-right : floor(($gutter-width / 2));
	}
}

/**
 * Grid-Variante generieren.
 *
 * @param string $ident        Teil des neuen Selectors der an '.row-' angefuegt
 *                             wird. Bsp. (md): .row-md
 * @param string $gutter-width Spaltenabstand
 * @param string $grid-columns Anzahl der Spalten
 */
@mixin grid-gutter-variant($ident, $gutter-width: 0, $grid-columns: 12) {
	.row-#{$ident} {
		margin-left  : ceil(($gutter-width / -2));
		margin-right : floor(($gutter-width / -2));
		@include grid-gutter-variant-make-columns($gutter-width, $grid-columns);
	}
}