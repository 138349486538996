/**
 * Tabellen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$table-nested-bg-color : $color-white !default;

$table-default-color      : $body-text-color !default;
$table-default-margin     : $gap-base-large 0;
$table-default-padding    : $comp-padding-vertical-base $comp-padding-horizontal-base;
$table-default-text-align : left;
$table-default-width      : 100%;

$table-default-caption-color      : $color-gray-light !default;
$table-default-caption-padding    : $comp-padding-vertical-base $comp-padding-horizontal-base;
$table-default-caption-text-align : left;

$table-default-header-color          : $brand-secondary !default;
//$table-default-header-font-family    : $font-family-base; // (1)(2)
$table-default-header-font-size      : $font-size-large; // (1)(2)
$table-default-header-font-weight    : $font-weight-bold;
$table-default-header-text-align     : left;
$table-default-header-text-transform : none;

$table-default-row-header-color       : $table-default-header-color !default;
$table-default-row-header-font-weight : $font-weight-normal;
$table-default-row-header-text-align  : left;

/* -----------------------------------------------------------------------------
 * @.table
 * -------------------------------------------------------------------------- */

$table-header-color    : $brand-primary !default;
$table-header-bg-color : rgba($brand-primary, 0.2) !default;
$table-header-border   : 3px solid lighten(desaturate(adjust-hue($brand-primary, -0.8000), 15.2401), 33.3333) !default;
$table-row-border      : 1px solid darken($color-gray, 10%) !default;

/* -----------------------------------------------------------------------------
 * @.table-condensed
 * -------------------------------------------------------------------------- */

$table-condensed-cell-padding-horizontal : $comp-padding-horizontal-base;
$table-condensed-cell-padding-vertical   :  $comp-padding-vertical-small;

/* -----------------------------------------------------------------------------
 * @.table-bordered
 * -------------------------------------------------------------------------- */

$table-bordered-border: 1px solid $color-gray !default;

/* -----------------------------------------------------------------------------
 * @.table-striped
 * -------------------------------------------------------------------------- */

$table-zebra-bg-color-even : $color-even !default;
$table-zebra-bg-color-odd  : $color-odd !default;

/* -----------------------------------------------------------------------------
 * @Hover
 * -------------------------------------------------------------------------- */

$table-hover-bg-color-hover: #fff !default;

/* -----------------------------------------------------------------------------
 * @Hintergrundfarben
 * -------------------------------------------------------------------------- */

$table-bg-color-active  : $color-odd !default;
$table-color-active     : $color-odd !default;

$table-bg-color-success : lighten($state-success-bg-color, 10%) !default;
$table-color-success    : $state-success-color !default;

$table-bg-color-info :  lighten($state-info-bg-color, 5%) !default;
$table-color-info    : $state-info-color !default;

$table-bg-color-warning :  lighten($state-warning-bg-color, 5%) !default;
$table-color-warning    : $state-warning-color !default;

$table-bg-color-danger :  lighten($state-danger-bg-color, 10%) !default;
$table-color-danger    : $state-danger-color !default;
