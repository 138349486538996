/**
 * Rating stars
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$rating-stars-bg-color : transparent !default;
$rating-stars-color    : $brand-secondary !default;

$rating-stars-star-bg-color : transparent !default;
$rating-stars-star-color    : $brand-secondary !default;
$rating-stars-star-margin   : 0 1px;