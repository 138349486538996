/**
 * Grundgeruest
 *
 * Grundkonfiguration
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$body-bg-color : $color-white !default;
$body-padding  : 0 !default;

$website-max-width     : 1200px !default; // 1440px
$website-default-width : 100% !default;

/* =============================================================================
 *
 * Grundcontainer der Seite
 *
 * ========================================================================== */

/* -----------------------------------------------------------------------------
 * Seitenkopf
 * -------------------------------------------------------------------------- */

$main-header-bg-color : $color-white !default;
$main-header-height   : 90px;

/* -----------------------------------------------------------------------------
 * Seitenfuss
 * -------------------------------------------------------------------------- */

$main-footer-bg-color : $color-gray !default;
$main-footer-color    : $brand-secondary !default;

/* -----------------------------------------------------------------------------
 * Showcase
 * -------------------------------------------------------------------------- */

$main-showcase-bg-color : $color-white !default;
$main-showcase-height   : 560px;

/* -----------------------------------------------------------------------------
 * Breadcrumb
 * -------------------------------------------------------------------------- */

$main-breadcrumb-bg-color : rgba($brand-primary, 0.8) !default;
$main-page-main-width     : 72%;

/* -----------------------------------------------------------------------------
 * Seite
 * -------------------------------------------------------------------------- */

$main-page-bg-color   : $color-white !default;
$main-page-main-width : 72%;