/**
 * Wells
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$well-bg-color      : transparent !default;
$well-border        : 2px solid $color-gray-lighter !default;
$well-border-radius : 0; //$comp-border-radius-base
$well-box-shadow    : none; //inset 0 1px 1px rgba(0, 0, 0, .05)
$well-margin        : $comp-margin-horizontal-base;
$well-padding       : $comp-padding-horizontal-base;

$well-small-border-radius : 0; //$comp-border-radius-small
$well-small-margin        : $comp-margin-horizontal-small;
$well-small-padding       : $comp-padding-horizontal-small;

$well-large-border-radius : 0; //$comp-border-radius-large
$well-large-margin        : $comp-margin-horizontal-large;
$well-large-padding       : $comp-padding-horizontal-large;

/* -----------------------------------------------------------------------------
 * 'Wells' scrollbar.
 * -------------------------------------------------------------------------- */

$well-scrollable-height       : 210px;
$well-scrollable-height-small : $well-scrollable-height - 40;
$well-scrollable-height-large : $well-scrollable-height + 40;