/**
 * Modals
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$modal-backdrop-bg-color : rgba($color-white, 0.8) !default;

$modal-dialog-margin  : 0 auto;
$modal-dialog-padding : ($gap-base-small * 2);

$modal-close-pos-bottom : auto;
$modal-close-pos-left   : auto;
$modal-close-pos-right  : $padding-base-vertical;
$modal-close-pos-top    : $padding-base-vertical;

$modal-border        : 0 none; //1px solid $color-white;
$modal-border-radius : 0; //$comp-border-radius-base;
$modal-box-shadow    : none; //0 3px 9px rgba(0, 0, 0, .5);
$modal-bg-color      : $color-white !default;
$modal-inner-padding : $padding-base-vertical $padding-base-horizontal;