/**
 * Bilder
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$thumbnail-border-radius : 0;

$thumbnail-caption-bg-color    : transparent !default;
$thumbnail-caption-color       : lighten(saturate(adjust-hue($color-black, 0.0000), 0.0000), 58.0392) !default;
//$thumbnail-caption-font-size   : 15px; // zum Anwenden einkommentieren
$thumbnail-caption-font-style  : $font-style-italic;
$thumbnail-caption-font-weight : $font-weight-light;
$thumbnail-caption-padding     : $comp-margin-vertical-large 0 0; // top, right, bottom, left

$thumbnail-align-margin-horizontal : ($comp-margin-horizontal-base * 2); // nur ein Wert
$thumbnail-align-margin-vertical   : $comp-margin-horizontal-base; // nur ein Wert

$thumbnail-size       : 100%;
$thumbnail-size-small : 18%;
$thumbnail-size-large : 45%;
$thumbnail-size-full  : 100%;

$thumbnail-zoom-bg-color       : rgba($brand-secondary, 0.1) !default;
$thumbnail-zoom-bg-color-hover : rgba($brand-primary, 0.6) !default; // zum Anwenden einkommentieren
$thumbnail-zoom-color          : $color-white !default;
$thumbnail-zoom-color-hover    : $color-white !default; // zum Anwenden einkommentieren
$thumbnail-zoom-font-size      : $font-size-xlarge;
$thumbnail-zoom-icon-content   : "\f065" ; // "\f00e" !default;
$thumbnail-zoom-size           : 36px;
