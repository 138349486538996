/**
 * Seitenblaettern (Pagination)
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$pagination-align          : center; // (1,2)
$pagination-border-radius  : $comp-border-radius-base;
$pagination-color          : $brand-secondary !default;
$pagination-font-size      : $font-size-base;
$pagination-line-height    : 1; // (1,2)
$pagination-margin         : ($gap-base-large * 2) 0;
$pagination-text-transform : none;

$pagination-item-bg-color     : $color-gray-lighter !default;
$pagination-item-border-color : $color-gray !default;
$pagination-item-border-width : $comp-border-width;
$pagination-item-border-style : $comp-border-style;
$pagination-item-font-weight  : $font-weight-bold; // (1,2)
$pagination-item-color        : $pagination-color !default;
$pagination-item-margin       : 0 $gap-base-small 0 0;
$pagination-item-padding      : $padding-base-vertical-xsmall $padding-base-horizontal-small;

$pagination-item-bg-color-hover     : $color-gray-light !default;
$pagination-item-border-color-hover : $pagination-item-border-color !default;
$pagination-item-color-hover        : $pagination-item-color !default;

$pagination-item-bg-color-active     : $color-gray-light !default;
$pagination-item-border-color-active : $brand-secondary !default;
$pagination-item-color-active        : $brand-secondary !default;

$pagination-item-bg-color-disabled     : transparent !default;
$pagination-item-border-color-disabled : $pagination-item-border-color !default;
$pagination-item-color-disabled        : $color-gray-dark !default;
$pagination-item-opacity-disabled      : 0.4 !default; // (1)

/**
 * Vor- und Zurückbutton
 */

$pagination-ctrl-bg-color     : transparent !default;
$pagination-ctrl-border-color : transparent !default;
$pagination-ctrl-border-width : $comp-border-width;
$pagination-ctrl-border-style : $comp-border-style;
$pagination-ctrl-color        : $color-gray-dark !default;
$pagination-ctrl-font-weight  : $font-weight-bold; // (1,2)

$pagination-ctrl-bg-color-hover     : transparent !default;
$pagination-ctrl-border-color-hover : transparent !default;
$pagination-ctrl-color-hover        : $pagination-item-color !default;