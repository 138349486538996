/**
 * Indikatoren (Ladeanzeige)
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$indicator-border-color : $brand-tertiary !default;
$indicator-border-width : 4px;
$indicator-size         : 24px; // = lg

$indicator-md-border-width : 3px;
$indicator-md-size         : 20px;

$indicator-sm-border-width : 3px;
$indicator-sm-size         : 16px;

$indicator-xs-border-width : 2px;
$indicator-xs-size         : 12px;