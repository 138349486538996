/**
 * Popver
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$popover-arrow-width   : 5px; // (1)(2)

$popover-bg-color        : $brand-info !default; // (1)(2)
$popover-border-color    : transparent !default; // (1)(2)
$popover-border-style    : none; // (1)(2)
$popover-border-width    : 0; // (1)(2)
$popover-border-radius   : 4px; // (1)(2)
$popover-box-shadow      : none; // (1)(2)
$popover-color           : $color-white !default; // (1)(2)
$popover-content-padding : 3px 8px; // (1)(2)
$popover-font-size       : $font-size-small; // (1)(2)
//$popover-font-weight     : $font-weight-normal; // (1)(2)
$popover-max-width       : 250px; // (1)(2)
$popover-opacity         : 1 !default; // (1)
$popover-padding         : $padding-base-vertical; // (1)(2)

$popover-title-border-color : transparent !default; // (1)(2)
$popover-title-border-style : none; // (1)(2)
$popover-title-border-width : 0; // (1)(2)
$popover-title-padding      : 3px 8px; // (1)(2)
//$popover-title-bg-color     : $color-white !default; // (1)(2)
//$popover-title-font-size    : $font-size-xsmall; // (1)(2)
$popover-title-text-align   : center; // (1)(2)
$popover-title-padding      : 3px 8px; // (1)(2)