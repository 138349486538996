/**
 * Navigationen
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =============================================================================
 *
 * Global
 *
 * ========================================================================== */

$nav-font-family    : $font-family-secondary; // (1, 2)
$nav-font-size      : $font-size-large; // (1, 2)
$nav-font-style     : normal !default; // (1) (2)
$nav-font-weight    : $font-weight-bold; // (1, 2)
$nav-font-style     : $font-style-normal; // (1, 2)
$nav-text-transform : uppercase; // (1, 2)
$nav-font-variant   : normal; // (1, 2)

/* =============================================================================
 *
 * Breadcrumb
 *
 * ========================================================================== */

$nav-breadcrumb-color        : $color-white !default;
$nav-breadcrumb-color-hover  : $color-white !default;
$nav-breadcrumb-color-active : $color-white !default;
$nav-breadcrumb-font-size    : $font-size-small; // (1, 2)
$nav-breadcrumb-font-weight  : $font-weight-light;

$nav-breadcrumb-title-color       : $color-white !default;
$nav-breadcrumb-title-font-weight : $font-weight-light;
$nav-breadcrumb-title-show        : true;

/* =============================================================================
 *
 * Step Wizard
 *
 * ========================================================================== */

$nav-stepwizard-border-radius   : 0;
$nav-stepwizard-border-width    : 2px;
$nav-stepwizard-border-style    : solid;
$nav-stepwizard-border-collapse : true;
$nav-stepwizard-font-size       : $font-size-large;
$nav-stepwizard-padding         : $padding-base-vertical-small $padding-base-horizontal;

$nav-stepwizard-bg-color     : transparent !default;
$nav-stepwizard-border-color : lighten($color-gray-dark, 20%) !default;
$nav-stepwizard-color        : $color-gray-dark !default;

$nav-stepwizard-bg-color-hover     : transparent !default;
$nav-stepwizard-border-color-hover : $color-gray-darker !default;
$nav-stepwizard-color-hover        : $color-gray-darker !default;