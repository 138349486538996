/**
 * Tab-Focus
 *
 * @copyright 2017 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

@mixin tab-focus() {
	outline        : thin dotted; // Default
	outline        : 5px auto -webkit-focus-ring-color; // WebKit
	outline-offset : -2px;
}