/**
 * Nested Map-Funktionen.
 *
 * (https://github.com/at-import/Sassy-Maps/tree/0.x.x/sass/sassy-maps)
 *
 * @author Hugo Giraudel
 * @author Heiko Pfefferkorn (https://i-fabrik.de, Modifications)
 */

$private-suppress-warnings: false !default;

/**
 * Map Get Deep Warnungen.
 *
 * Ausgabe einer Warnung wenn abgerufener Wert `null` sein sollt.
 *
 * return null
 */
@function map-get-deep-warning($warn, $get, $key) {
	@if not $private-suppress-warnings {
		@if $get == null {
			@warn "Map has no value for key search `#{$warn}`";
		} @else if type-of($get) != 'map' {
			@warn "Non-map value found for key search `#{$warn}`, cannot search for key `#{$key}`";
		}
	}
	@return null;
}

/**
 * Angefragte Werte in Nested-Maps finden und zurückgeben.
 *
 * return {*}
 */
@function map-get-deep($map, $keys...) {
	@if length($keys) == 1 {
		$keys : nth($keys, 1);
	}

	$warn   : "#{nth($keys, 1)}";
	$length : length($keys);
	$get    : map-get($map, nth($keys, 1));

	@if $length > 1 {
		@for $i from 2 through $length {
			@if $get != null and type-of($get) == 'map' {
				$warn : $warn + "->#{nth($keys, $i)}";
				$get : map-get($get, nth($keys, $i));

				@if $get == null {
					@return map-get-deep-warning($warn, $get, nth($keys, $i));
				}
			} @else {
				@return map-get-deep-warning($warn, $get, nth($keys, $i));
			}
		}
	}

	@return $get;
}