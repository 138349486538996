/**
 * Druckausgabe (projektspezifisch).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Aus-, Einblenden von bestimmten Elementen.
 */

.hide-on-print,
.main-showcase,
.main-header > .main-header-wrap > .nav,
.main-header .trigger,
.main-footer .nav-service,
.cookie-bar,
.main > .section-find-district { @extend %pmu-no-print; }

.show-on-print { @extend %pmu-print; }

/* -----------------------------------------------------------------------------
 * Vendors
 * -------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Komponenten
 * -------------------------------------------------------------------------- */

/**
 * '../components/_links'
 */

//a[href]:after { content : " (" attr(href) ")"; }
a[href]:after { content : ""; }

/**
 * '../components/_articles'
 */

.article {
	> .article-body > .article-body-section {
		margin-bottom : $margin-base-vertical-large;
		margin-top    : $margin-base-vertical-large;
	}

	&-box {
		@extend %pmu-break-inside-avoid;

		&.property-box {
			> .article-box-wrap { padding-bottom : $padding-base-vertical-large; }

			.controls { display : none; }
		}
	}
}

/**
 * @ '../components/_stacks'
 */

.stack-article-box {
		margin-top : 0;

		> .article-box {
			height     : auto !important;
			//width      : 50%;
			margin-top : 0 !important;

			&:nth-child(2n+1) { clear : both; }

		}
	}

/**
 * @ '../components/_tables'
 */

thead { display : table-header-group; }

tr { page-break-inside : avoid; }

/**
 * @ '../components/_tooltip'
 */

.tooltip { display : none !important; }

/**
 * @ '../components/_googlemaps'
 */

.googlemap {
	height      : 500px !important;
	padding-bottom : 0 !important;
	width       : 100%;

	&-map {
		height   : 100%;
		bottom   : auto !important;
		left     : auto !important;
		position : relative !important;
		right    : auto !important;
		top      : auto !important;
	}

	> img {width:100%;}
}

/**
 * @ '../components/_vcards'
 */

.vcard {
	.thumbnail {
		> .image {
			background-image : none !important;
			padding-bottom   : 0 !important;
			padding-top      : 0 !important;

			> img {
				bottom   : auto;
				display  : block;
				left     : auto;
				position : relative;
				right    : auto;
				top      : auto;
			}
		}
	}
}

/* -----------------------------------------------------------------------------
 * Kernaufbau
 * -------------------------------------------------------------------------- */

/**
 * @ '../core/_scaffolding'
 */

.page-max-width,
.main-breadcrumb .nav-breadcrumb,
.main > .section > *,
.main > .section-district-finder > .article > .article-header > * { @extend %pmu-reset-max-width; }

.main > .section {
	padding-bottom    : $padding-base-horizontal-large !important;
	padding-top       : $padding-base-horizontal-large !important;

	&:not(:first-child) { page-break-before : always; }
}

/**
 * @ '../core/_header'
 */

.main-header {
	> .main-header-wrap {
		@extend %pmu-reset-max-width;

		height         : auto;
		padding-bottom : $padding-base-vertical-large;
	}
}

/**
 * @ '../core/_footer'
 */

.main-footer {
	@extend %pmu-reset-max-width;

	margin-top : 0;//$margin-base-vertical-large;

	&-wrap { padding-bottom : 0; }

	.copy {
		display    : block;
		float      : none;
		text-align : center;
		width      : 100%;
	}
}

/**
 * @ '../core/_showcase'
 */

.main-showcase > .component > .component-wrap { @extend %pmu-reset-max-width; }

/**
 * @ '../core/_elements'
 */