/**
 * Tabs
 *
 * Komponentenkonfiguration(en)
 *
 * (1) Diese Variable ist optional. Wird sie nicht benoetigt, vielleicht reichen
 *     die vererbten Eigenschaften aus, dann kann diese Variable auskommentiert
 *     werden. Die Komponente selbst prueft auf Vorhandensein und reagiert
 *     entsprechend darauf.
 * (2) Eine Vererbung von seitens des Standards tritt in Kraft wenn diese
 *     Variable auskommentiert wird.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$tabs-border-radius : 0;

/**
 * Toggle
 */

$tabs-toggle-bg-color        : $color-gray-lighter !default;
$tabs-toggle-border-color    : $color-gray !default;
$tabs-toggle-border-style    : solid;
$tabs-toggle-border-width    : $comp-border-width;
$tabs-toggle-color           : $brand-secondary !default;
//$tabs-toggle-font-size       : $font-size-large; // (1)(2)
$tabs-toggle-font-weight     : $font-weight-bold; // (1)
//$tabs-toggle-padding         : ($comp-padding-horizontal-base - 2) $comp-padding-horizontal-base;
$tabs-toggle-padding         : ($comp-padding-horizontal-base - 2) 0;
$tabs-toggle-text-decoration : none;
$tabs-toggle-height          : ($line-height-computed + ($comp-padding-vertical-large * 2) + 2);

// Hover
$tabs-toggle-color-hover        : $brand-primary !default;
$tabs-toggle-bg-color-hover     : $tabs-toggle-bg-color !default;
$tabs-toggle-border-color-hover : $tabs-toggle-border-color !default;

// Active
$tabs-toggle-color-active        : $brand-primary !default;
$tabs-toggle-bg-color-active     : $color-white !default;
$tabs-toggle-border-color-active : $tabs-toggle-border-color !default;

/**
 * Pane
 */

$tabs-pane-border-color : $tabs-toggle-border-color !default;
$tabs-pane-border-style : solid;
$tabs-pane-border-width : $comp-border-width;
$tabs-pane-padding      : $comp-padding-vertical-base $comp-padding-horizontal-base !default;