/**
 * Popover.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Popover-Variante.
 *
 * @param string  $color              Textfarbe
 * @param string  $bg-color            Hintergrundfarbe
 * @param string  $border-color       Rahmenfarbe. Wenn nicht
 *                                    angegeben, dann autom. Bestimmung per
 *                                    'darken()' von $bg-color.
 * @param string  $opacity            Transparenz.
 */
@mixin popover-variant($color, $bg-color, $border-color : $bg-color, $opacity : .85) {
	background-color : $bg-color;
	border-color     : $border-color;
	color            : $color;

	&.fade.in { opacity : $opacity; }

	&.top > .arrow {
		&,
		&:after { border-top-color : $border-color; }
	}

	&.right > .arrow {
		&,
		&:after { border-right-color : $border-color; }
	}

	&.bottom > .arrow {
		&,
		&:after { border-bottom-color : $border-color; }
	}

	&.left > .arrow {
		&,
		&:after { border-left-color : $border-color; }
	}

	.popover-title {
		background-color : $bg-color;
		border-color     : $border-color;
		color            : $color;
	}
}